@mixin anchor_link() {
  text-decoration: none;

  color: black;
}

header {
  h1 {
    background-color: #66ffaf;

    margin: auto;
    padding: 5px;
    width: 100%;
    height: auto;

    text-align: right;
    font-size: .9rem;
    font-weight: normal
  }
}

@media all and (max-width: 767px) {

}


@media all and (min-width: 768px) and (max-width: 1439px) {

}


@media all and (min-width: 1440px) {

header {
  div.title-on-image {
    position: relative;
    margin: 0;
    padding: 0;
    img {
      margin: 0;
      padding: 0;

      aspect-ratio: 58/10;
    }
    h2.site-title {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%,-40%);

      margin: 0 auto;

      font-size: 4.8rem;
      font-weight: normal;

      letter-spacing: 14px;
      text-shadow: 0 20px 10px rgb(64 64 64 / 50%);
    }
  }
  nav.header-wrapper {
    width: min(80%, 1280px);
    height: auto;
    margin: auto;
    ul.page-list {
      padding-left: 0;

      margin: 0;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: stretch;
      li.page-item {
        list-style-type: none;

        width: Calc(100%/5);
        height: auto;
        text-align: center;

        font-size: 1.6rem;
        font-weight: normal;
        a:link,a:hover,a:active,a:visited {
          @include anchor_link();
        }
        a:hover {
          text-decoration: underline;
        }
        ul.sub-page-list {
          display: none;

          padding-left: 0;

          width: 100%;
          height: auto;
          margin: 0;
          li.sub-page-item {
            list-style-type: none;
          }
        }
      }
    }
  }
}

}

.pagetop {
  color: #fff;
  width: 3.3rem;
  height: 3.3rem;
  text-align: center;
  cursor: pointer;
  background-color: #0000004d;
  border-radius: 3.3rem;
  font-size: 1.5rem;
  line-height: 3.3rem;
  text-decoration: none;
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.pagetop:hover {
  background-color: #ed702b;
  text-decoration: none;
  color: #fff !important;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.clearfix:after, .clearfix:before {
  content: "";
  clear: both;
  display: block;
}

.clearfix {
  display: block;
}

html, body {
  height: 100%;
}

body > footer {
  position: sticky;
  top: 100vh;
}

img {
  width: 100%;
  height: auto;
}

*, :before, :before, :after, :after {
  box-sizing: border-box;
  letter-spacing: 2px;
  font-family: "Font Awesome 5 Free", Osaka, Meiryo UI, sans-serif;
  line-height: 1.4;
}

.youtube {
  width: 90%;
  aspect-ratio: 16 / 9;
  text-align: center;
  margin: auto;
}

.youtube iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  section {
    width: min(80%, 670px);
    height: auto;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  section {
    width: 80%;
    height: auto;
    margin: auto;
  }
}

@media (min-width: 1440px) {
  section {
    width: min(80%, 1280px);
    height: auto;
    margin: auto;
  }

  section.nest {
    width: 100%;
    height: auto;
  }
}

header h1 {
  width: 100%;
  height: auto;
  text-align: right;
  background-color: #66ffaf;
  margin: auto;
  padding: 5px;
  font-size: .9rem;
  font-weight: normal;
}

@media (min-width: 1440px) {
  header div.title-on-image {
    margin: 0;
    padding: 0;
    position: relative;
  }

  header div.title-on-image img {
    aspect-ratio: 58 / 10;
    margin: 0;
    padding: 0;
  }

  header div.title-on-image h2.site-title {
    letter-spacing: 14px;
    text-shadow: 0 20px 10px #40404080;
    margin: 0 auto;
    font-size: 4.8rem;
    font-weight: normal;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  header nav.header-wrapper {
    width: min(80%, 1280px);
    height: auto;
    margin: auto;
  }

  header nav.header-wrapper ul.page-list {
    flex-flow: row;
    justify-content: space-evenly;
    align-items: stretch;
    margin: 0;
    padding-left: 0;
    display: flex;
  }

  header nav.header-wrapper ul.page-list li.page-item {
    width: 20%;
    height: auto;
    text-align: center;
    font-size: 1.6rem;
    font-weight: normal;
    list-style-type: none;
  }

  header nav.header-wrapper ul.page-list li.page-item a:link, header nav.header-wrapper ul.page-list li.page-item a:hover, header nav.header-wrapper ul.page-list li.page-item a:active, header nav.header-wrapper ul.page-list li.page-item a:visited {
    color: #000;
    text-decoration: none;
  }

  header nav.header-wrapper ul.page-list li.page-item a:hover {
    text-decoration: underline;
  }

  header nav.header-wrapper ul.page-list li.page-item ul.sub-page-list {
    width: 100%;
    height: auto;
    margin: 0;
    padding-left: 0;
    display: none;
  }

  header nav.header-wrapper ul.page-list li.page-item ul.sub-page-list li.sub-page-item {
    list-style-type: none;
  }
}

@media (min-width: 1440px) {
  div.mysite-block-wrapper {
    background-color: #ffaf91;
  }

  div.mysite-block-wrapper div.mysite-block-width {
    width: min(80%, 1280px);
    height: auto;
    margin: auto;
  }

  div.mysite-block-wrapper div.mysite-block-width ul.mysite-block {
    flex-flow: row;
    justify-content: space-evenly;
    align-items: stretch;
    margin: auto;
    padding: 1rem 0;
    display: flex;
  }

  div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements {
    list-style-type: none;
  }

  div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list {
    padding-left: 0;
  }

  div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list li.mysite-item {
    font-size: 1.4rem;
    list-style-type: none;
  }

  div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list li.mysite-item a:link, div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list li.mysite-item a:hover, div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list li.mysite-item a:active, div.mysite-block-wrapper div.mysite-block-width ul.mysite-block li.mysite-elements ul.mysite-list li.mysite-item a:visited {
    color: #333;
    text-decoration: none;
  }

  div.copyright-wrapper {
    background-color: #ff632a;
  }

  div.copyright-wrapper .copyright {
    text-align: center;
    margin: auto;
    padding: .3rem;
    font-size: 1.4rem;
  }
}

/*# sourceMappingURL=index.e71110d3.css.map */

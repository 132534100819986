@media all and (max-width: 767px) {

section {
  margin: auto;
  width: min(80%, 670px);
  height: auto;
}

}


@media all and (min-width: 768px) and (max-width: 1439px) {

section {
  margin: auto;
  width: 80%;
  height: auto;
}

}


@media all and (min-width: 1440px) {

section {
  margin: auto;
  width: min(80%, 1280px);
  height: auto;
}
section.nest {
  width: 100%;
  height: auto;
}

}

@media all and (max-width: 767px) {

}


@media all and (min-width: 768px) and (max-width: 1439px) {

}


@media all and (min-width: 1440px) {

div.mysite-block-wrapper {
  background-color: #ffaf91;
  div.mysite-block-width {
    width: min(80%, 1280px);
    height: auto;
    margin: auto;
    ul.mysite-block {
      padding-left: 0;

      margin: auto;
      padding: 1rem 0;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: stretch;
      li.mysite-elements {
        list-style-type: none;
        ul.mysite-list {
          padding-left: 0;
          li.mysite-item {
            list-style-type: none;

            font-size: 1.4rem;
            a:link,a:hover,a:active,a:visited {
              text-decoration: none;

              color: #333333;
            }
          }
        }
      }
    }
  }
}

div.copyright-wrapper {
  background-color: #ff632a;
  .copyright {
    margin: auto;
    padding: .3rem;
    text-align: center;
    font-size: 1.4rem;
  }
}

}
